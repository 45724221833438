<template>
  <div id="conteudo">
    <Titulo texto="Pintura" opacityTitle="Serviços" :servico="true" />
    <cardServicos
      v-for="(conteudo, index) in conteudoCards"
      :key="index"
      :titulo="conteudo.titulo"
      :image="conteudo.imagem"
      :alt="conteudo.alt"
    >
      {{ conteudo.texto }}
    </cardServicos>
    <b-container class="estrutura">
      <b-row>
        <b-col>
          <div class="pl-2 pl-md-5">
            <h3 tabindex="0">Estrutura do setor</h3>
            <ul>
              <li>
                <p tabindex="0">
                  Linha de Tratamento com tanque de capacidade para 6.900
                  litros;
                </p>
              </li>
              <li>
                <p tabindex="0">Tanque de 3.000 mm x 1.600 mm x 1.500 mm.</p>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import cardServicos from "../../components/cardServicos.vue";
import Titulo from "../../components/Titulo.vue";

export default {
  components: { cardServicos, Titulo },
  data() {
    return {
      conteudoCards: [
        {
          titulo: "Pintura eletrostática",
          texto:
            "Sua principal vantagem é ser ecológica. Utilizada majoritariamente em superfícies metálicas, utiliza cargas elétricas para a fixação da tinta. ",
          imagem: "pintura/pintura1.webp",
          alt: "Pintura eletrostática",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.estrutura {
  color: #ededed;
  h3 {
    font-family: Monument Extended, sans-serif;
    font-size: 2.25rem;
  }

  ul {
    font-size: 1.25rem;
    color: #05c8ef;
    padding-inline-start: 20px;

    li {
      p {
        color: #ededed;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .estrutura {
    h3 {
      font-size: 1.6rem;
    }

    ul {
      font-size: 1rem;
    }
  }
}
</style>